import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { AcquisitionService } from "../services/AcquisitionService";
import { StaffService } from "../services/StaffService";
import { RoleService } from "../services/RoleService";
import { SystemUserService } from "../services/SystemUserService";
import { enumLookupTypes } from "../helpers/common.constants";


export default class SystemUserActions {

  async InitAsync() {
    let response  = await new RoleService().GetAll(null);
    let responseOffices  = await new LookupDataService().GetAllByTypeID({ldTypeID: enumLookupTypes.Offices});
    let responses  = [response, responseOffices]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record : any) {

    let jsonInputData : IErrorsInput[]  = [
      {
        element: "FullName",
        type: "required",
        value: Record.acName,
        message: 'Full Name is required'
      },
      {
        element: "Email",
        type: "email",
        value: Record.Email,
        message: 'Description is required'
      },
      {
        element: "Password",
        type: "required",
        value: Record.Password,
        message: 'Password is required'
      },
      {
        element: "ConfirmPassword",
        type: "required",
        value: Record.ConfirmPassword,
        message: 'Confirm Password is required'
      },
      {
        element: "Password",
        type: "equal",
        value: Record.ConfirmPassword,
        relatedValue: Record.Password,
        message: 'Password and Confirm Password must be same'
      },
      {
        element: "Role",
        type: "SelectEmpty",
        value: Record.Role,
        message: 'Role is required'
      }
    ];


    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to save",
        ValidationMessages: validationMessages //vrati gi greskite za GUI
      };
      return response;
    }

    
    //napravi validacija
    let response = await new SystemUserService().Save(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record : any) {
   
    //napravi validacija
    let response = await new SystemUserService().Search(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ChangePasswordAsync(Record : any) {
   
    //napravi validacija
    let response = await new SystemUserService().ChangePassword(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record : any) {
   
    //napravi validacija
    let response = await new SystemUserService().Edit(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}