// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumData, enumPage,
  enumPagination, enumSQLS,
  enumSharedMsgs
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { MessageHelper } from "../../helpers/MessageHelper";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import OfficeActions from "../../actions/OfficeActions";
import ZAutoSuggestField from "../../controls/ZAutoSuggestField";
import ZCheckboxField from "../../controls/ZCheckboxField";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2024,
  Month: 4
};

export default function OfficeSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [YearsData, setYearsData] = useState(enumData.YEARS);

  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [LookupData, setLookupData] = useState([]);
  const [CurrencyExcRates, setCurrencyExcRates] = useState([]);
  const [RecordDelete, setRecordDelete] = useState(RecordDefault);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => { searchData(); }, [location.pathname + "/" + location.search]);


  // //3 --> Pagination Change 
  useEffect(() => { if (IsPaginationChange) { searchData(); } }, [IsPaginationChange]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  
  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);


    let responses = await new OfficeActions().SearchAsync(Record);
    if (!responses.error) {
      const records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({ ...a, ["PageIndex"]: pageIndex, ["TotalRecords"]: totalRecords, ["TotalPages"]: totalPages }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false);       //za da se pali na Search Click


      setRecords(records);
      
      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false);       //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }


  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "This process could take a while, please wait!");
      setMessageData(result);

      let response = await new OfficeActions().SaveAsync(Records);
      if (!response.error) {

        await searchDataAsync();

        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);

      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };



  const handleModalClose = (doDelete) => {
    if (doDelete) {
      async function fetchData() {
        //Load Start
        let result = msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "This process could take a while, please wait!");
        setMessageData(result);


        let response = await new EmployeeActions().TransferHoursDeleteAsync(RecordDelete);
        if (!response.error) {

          await searchDataAsync();

          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Transfer hours deleted!");
          setMessageData(result);

        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, response);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
        }
      }
      fetchData();
    }
    setIsModalOpen(false);
  };

  
  const handleChangeTransfer = (e) => {

    const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value + ":" + primaryKey);

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find((a) => a.ldLookupDataID.toString() === primaryKey.toString());
    currentCheck[name] = value;
    currentCheck["IsChanged"] = true;

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex((x) => x.ldLookupDataID.toString() === primaryKey.toString());
    newRecords[propertyIndex] = currentCheck;
    setRecords(newRecords);
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <RenderTable
        Records={Records}
        Record={Record}
        onHandleChange={handleChangeTransfer}
        onHandleSave={saveData}
        MessageData={MessageData}
        DataArray={[LookupData]}
      />


      <MyDialogYesNo IsOpen={IsModalOpen} HandleModalClose={handleModalClose} Title={enumSharedMsgs.WariningMsg} Content={enumSharedMsgs.DeleteAreYouSure}
      />
    </Typography>
  );
}

const RenderTable = ({ Records,
  Record,
  DataArray,
  onHandleChange,
  onHandleSave,
  onHandleDelete,
  MessageData }) => { //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650, border: "1" }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" align="center" sx={{'width':'50%'}}>
                  Office Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Active
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Data Entry
                </Typography>
              </TableCell>   

              <TableCell>
                <Typography variant="h6" align="center">
                  Verifier
                </Typography>
              </TableCell>                 
            </TableRow>

            {Records.length > 0 &&
              <TableRow>
                <TableCell colSpan={4} align="right" sx={{ pr: 5 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>

                </TableCell>
              </TableRow>
            }
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <RenderRow
                row={row}
                index={index}
                onHandleChange={onHandleChange}
                onHandleDelete={onHandleDelete}
                MessageData={MessageData}
                DataArray={DataArray}
              />
            ))}
          </TableBody>


          {/* PAGINATION FOOTER HERE */}
          {Records.length > 0 &&
            <TableFooter>
              <TableRow>

                <TableCell colSpan={4} align="right" >
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>

                </TableCell>
              </TableRow>
            </TableFooter>
          }

        </Table>
      </TableContainer>
    );
  }, [Records]);
};

const RenderRow = ({ row,
  index,
  onHandleChange,
  DataArray,
  onHandleDelete,
  MessageData }) => { //Record = Pagination



  return React.useMemo(() => {
    return (
      <TableRow
        key={row.eyEmployeeID}
        
      >

        <TableCell>{index+1}.  {row.ldValue}</TableCell>

        <TableCell align="center">
          <ZCheckboxField
            Id="ldActiveFL"
            Value={row.ldActiveFL}
            HandleChange={onHandleChange}
            PrimaryKey={row.ldLookupDataID}
          />
        </TableCell>

        <TableCell align="center" >
          <ZAutoSuggestField Id='ldCriteria'
                Value={row.ldCriteria}
                PrimaryKey={row.ldLookupDataID}
                PlaceHolder='Data Entry'
                HandleChange={onHandleChange}
                Required={true}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_Employee} />
        </TableCell>

        <TableCell align="center" >
          <ZAutoSuggestField Id='ldCriteria2'
                Value={row.ldCriteria2}
                PrimaryKey={row.ldLookupDataID}
                PlaceHolder='Verifier'
                HandleChange={onHandleChange}
                Required={true}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_Employee} />
        </TableCell>        
      </TableRow>
    );
  }, [row]);
};