import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { FinEmployeeService } from "../services/FinEmployeeService";

export default class FinEmployeeActions {
  
  async EmployeeSearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinEmployeeService().EmployeeSearch(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetEmployeeCoverByAllocationAsync(Record: any) {
    //napravi validacija
    let response = await new FinEmployeeService().GetEmployeeCoverByAllocation(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetEmployeeCoverByBudgetAsync(Record: any) {
    //napravi validacija
    let response = await new FinEmployeeService().GetEmployeeCoverByBudget(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetEmployeeCoverByProjectAsync(Record: any) {
    //napravi validacija
    let response = await new FinEmployeeService().GetEmployeeCoverByProject(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetEmployeeCoverByEmployeeAsync(Record: any) {
    //napravi validacija
    let response = await new FinEmployeeService().GetEmployeeCoverByEmployee(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportSearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinEmployeeService().ExportBySearch(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  
}
