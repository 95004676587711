import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class MyFinTimeService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload: any) {
    const url: string = "MyFinTime/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async Edit(payload: any) {
    const url: string = "MyFinTime/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
 
  async Save(payload: any) {
    const url: string = "MyFinTime/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  
  async Verify(payload: any) {
    const url: string = "MyFinTime/Verify";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
 
  
}
