import "./App.css";
import './styles/site.css';

import "./Header";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import BackContainer from "./BackContainer";
import Login from "./pages/Account/Login";
import Logout from "./pages/Account/Logout";

import Dashboard from "./pages/Home/Dashboard";
import AuthenticateHelper from "./helpers/AuthenticateHelper";
import PublicContainer from "./PublicContainer";
import React from "react";
import SystemUserManage from "./pages/SystemUser/SystemUserManage";
import SystemUserSearch from "./pages/SystemUser/SystemUserSearch";

import UserChangePassword from "./pages/User/UserChangePassword";
import UserOverview from "./pages/User/UserOverview";
import SystemLogSearch from "./pages/SystemLog/SystemLogSearch";
import { enumConfig, enumRoutes } from "./helpers/common.constants";
import NotificationSearch from "./pages/Notification/NotificationSearch";
import NotificationManage from "./pages/Notification/NotificationManage";
import NotificationBulkManage from "./pages/Notification/NotificationBulkManage";
import SystemUserOverview from "./pages/SystemUser/SystemUserOverview";
import EmployeeManage from "./pages/Employee/EmployeeManage";
import EmployeeOverview from "./pages/Employee/EmployeeOverview";
import EmployeeSearch from "./pages/Employee/EmployeeSearch";
import ContractManage from "./pages/Employee/ContractManage";
import LeaveRequestManage from "./pages/LeaveRequest/LeaveRequestManage";
import LeaveRequestSearch from "./pages/LeaveRequest/LeaveRequestSearch";

import ExactonlineSearch from "./pages/Exactonline/ExactonlineSearch";
import ExactonlineOverview from "./pages/Exactonline/ExactonlineOverview";
import LeaveRequestApproval from "./pages/LeaveRequest/LeaveRequestApproval";
import ActionPoints from "./pages/Home/ActionPoints";
import ExactonlineMigration from "./pages/Exactonline/ExactonlineMigration";
import MyLeaveRequestApprovals from "./pages/LeaveRequest/MyLeaveRequestApprovals";
// import StaffActionPoints from "./pages/Staff/StaffActionPoints";
import DeclarationManage from "./pages/Declaration/DeclarationManage";
import DeclarationApproval from "./pages/Declaration/DeclarationApproval";
import DeclarationSearch from "./pages/Declaration/DeclarationSearch";
import DeclarationCostManage from "./pages/Declaration/DeclarationCostManage";
import MyDeclarations from "./pages/Declaration/MyDeclarations";
import DeclarationDiemManage from "./pages/Declaration/DeclarationDiemManage";
import DeclarationPreview from "./pages/Declaration/DeclarationPreview";
import MyLeaveRequests from "./pages/LeaveRequest/MyLeaveRequests";
import LeaveRequestPreview from "./pages/LeaveRequest/LeaveRequestPreview";
import MyDeclarationApprovals from "./pages/Declaration/MyDeclarationApprovals";
import DeclarationProjectBudgetHolderChange from "./pages/Declaration/DeclarationProjectBudgetHolderChange";
import ReportLeaveRequestSummary from "./pages/LeaveRequest/ReportLeaveRequestSummary";
import ExactonlineTokenReply from "./pages/Exactonline/ExactonlineTokenReply";
import LeaveRequestCalendar from "./pages/LeaveRequest/LeaveRequestCalendar";
import MyEmployeeSearch from "./pages/Home/MyEmployeeSearch";
import DocumentSearch from "./pages/Document/DocumentSearch";
import VacancySearch from "./pages/Vacancy/VacancySearch";
import VacancyApplicant from "./pages/Vacancy/VacancyApplicant";
import VacancyManage from "./pages/Vacancy/VacancyManage";
import VacancyApplicantSearch from "./pages/Vacancy/VacancyApplicantSearch";
import ManualLogin from "./pages/Account/ManualLogin";
import EmployeeDocuments from "./pages/Employee/EmployeeDocuments";
import EmployeeCheckList from "./pages/Employee/EmployeeCheckList";
import TransferHoursSearch from "./pages/Employee/TransferHoursSearch";
import EmployeeOrgChart from "./pages/Employee/EmployeeOrgChart";
import PayrollManage from "./pages/Timesheet/PayrollManage";
import OfficeSearch from "./pages/Timesheet/OfficeSearch";
import FinProjectSearch from "./pages/Timesheet/FinProjectSearch";
import FinBudgetManage from "./pages/Timesheet/FinBudgetManage";
import FinAllocationManage from "./pages/Timesheet/FinAllocationManage";
import MyEmployeeToDoManage from "./pages/Home/MyEmployeeToDoManage";
import FinProjectManage from "./pages/Timesheet/FinProjectManage";
import FinProjectSubmit from "./pages/Timesheet/FinProjectSubmit";
import FinAllocationByEmployee from "./pages/Timesheet/FinAllocationByEmployee";
import FinProjectPreview from "./pages/Timesheet/FinProjectPreview";
import FinAllocationSearch from "./pages/Timesheet/FinAllocationSearch";
import FinProjectVerify from "./pages/Timesheet/FinProjectVerify";
import FinTimeManage from "./pages/Timesheet/FinTimeManage";
import MyTimeEntries from "./pages/Timesheet/MyTimeEntries";
import FinTimePreview from "./pages/Timesheet/FinTimePreview";
import FinTimeSearch from "./pages/Timesheet/FinTimeSearch";
import PayrollVerify from "./pages/Timesheet/PayrollVerify";
import FinRepEmployeeAllocation from "./pages/Timesheet/FinRepEmployeeAllocation";
import FinProjectOverviewPlanning from "./pages/Timesheet/FinProjectOverviewPlanning";
import FinEmployeePlanning from "./pages/Timesheet/FinEmployeePlanning";
import FinEmployeeSearch from "./pages/Timesheet/FinEmployeeSearch";
import FinTimeDetailPreview from "./pages/Timesheet/FinTimeDetailPreview";
import PayrolEmployeeOveview from "./pages/Timesheet/PayrolEmployeeOveview";
import PayrollSearch from "./pages/Timesheet/PayrollSearch";
import PayrollSubmit from "./pages/Timesheet/PayrollSubmit";
import FinTimeVerify from "./pages/Timesheet/FinTimeVerify";

const RecordData = {
  refreshFL: false,
  Record: null,
};

// function App2() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         {/* Ova e za google */}
//         <GoogleOAuthProvider clientId="821989582318-irklcu1i8l5r32r51vqbs2f2ihg28f8s.apps.googleusercontent.com">
//           <MyGoogle />
//         </GoogleOAuthProvider>
//       </header>
//     </div>
//   );
// }

function App() {
  const [recordData, setRecordData] = React.useState(RecordData);

  //Ke prati request do Header.js za refresh na Title-ot od New Lead vo Manage Lead
  const handleRefresh = (argsOfRecordData) => {
    setRecordData(argsOfRecordData);
  };

  //Ke go resetira recordData za da ne praka requesti se duri Acquisition/New ne se snimi nov record
  React.useEffect(() => {
    setRecordData(null);
  }, [recordData]); //default

  //return <Page1/>;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<PublicContainer MyContent={<Login />} />}
          ></Route>

          {/* 
            *************************
            PUBLIC  SECTION 
            *************************
          */}
          <Route
            path={enumRoutes.Security_Login}
            element={<PublicContainer MyContent={<Login />} />}
          ></Route>

          <Route
            path={enumRoutes.Security_ManualLogin}
            element={<PublicContainer MyContent={<ManualLogin />} />}
          ></Route>

          {/* <Route
            path={enumRoutes.Security_LoginExternal}
            element={<PublicContainer MyContent={<LoginExternal />} />}
          ></Route> */}

          <Route
            path={enumRoutes.Security_Logout}
            element={<PublicContainer MyContent={<Logout />} />}
          ></Route>

          <Route
            path={enumRoutes.Employee_Register}
            element={<PublicContainer MyContent={<EmployeeManage />} />}
          ></Route>

          {/* MAIN ROUTES FOR ALL STAFF   */}
          <Route
            path={enumRoutes.Main_Dashboard}
            element={<BackContainer MyContent={<Dashboard />} />}
          ></Route>

          <Route
            path={enumRoutes.Main_ActionsPoints}
            element={<BackContainer MyContent={<ActionPoints />} />}
          ></Route>

          <Route
            path={enumRoutes.Exactonline_TokenReply}
            element={<PublicContainer MyContent={<ExactonlineTokenReply />} />}
          ></Route>
          <Route
            path={enumRoutes.Main_MyProfile}
            element={<BackContainer MyContent={<EmployeeManage />} />}
          ></Route>

          <Route
            path={enumRoutes.Main_MyEmployeeToDOs}
            element={<BackContainer MyContent={<MyEmployeeToDoManage />} />}
          ></Route>

          <Route
            path={enumRoutes.Main_MyOrgChart}
            element={<BackContainer MyContent={<EmployeeOrgChart />} />}
          ></Route>

          <Route
            path={enumRoutes.Main_MyEmployeeSearch}
            element={<BackContainer MyContent={<MyEmployeeSearch />} />}
          ></Route>

          <Route
            path={enumRoutes.Employee_Overview}
            element={
              <BackContainer Section="Admin" MyContent={<EmployeeOverview />} />
            }
          ></Route>

          <Route
            path={enumRoutes.Employee_Documents}
            element={
              <BackContainer
                Section="Admin"
                MyContent={<EmployeeDocuments />}
              />
            }
          ></Route>

          <Route
            path={enumRoutes.Employee_CheckList_Onboarding}
            element={
              <BackContainer
                Section="Admin"
                MyContent={<EmployeeCheckList />}
              />
            }
          ></Route>
          <Route
            path={enumRoutes.Employee_CheckList_Offboarding}
            element={
              <BackContainer
                Section="Admin"
                MyContent={<EmployeeCheckList />}
              />
            }
          ></Route>
          <Route
            path={enumRoutes.Employee_CheckList_Competencies}
            element={
              <BackContainer
                Section="Admin"
                MyContent={<EmployeeCheckList />}
              />
            }
          ></Route>

          {/* 
            *************************
            Leave Request SECTION 
            *************************
          */}
          <Route
            path={enumRoutes.LeaveRequest_New}
            element={<BackContainer MyContent={<LeaveRequestManage />} />}
          ></Route>
          <Route
            path={enumRoutes.LeaveRequest_Calendar}
            element={<BackContainer MyContent={<LeaveRequestCalendar />} />}
          ></Route>

          <Route
            path={enumRoutes.LeaveRequest_PublicHoliday_New}
            element={<BackContainer MyContent={<LeaveRequestManage />} />}
          ></Route>

          <Route
            path={enumRoutes.LeaveRequest_Approval}
            element={<BackContainer MyContent={<LeaveRequestApproval />} />}
          ></Route>
          <Route
            path={enumRoutes.Staff_LeaveRequest_New}
            element={<BackContainer MyContent={<LeaveRequestManage />} />}
          ></Route>
          <Route
            path={enumRoutes.LeaveRequest_Preview}
            element={<BackContainer MyContent={<LeaveRequestPreview />} />}
          ></Route>
          <Route
            path={enumRoutes.LeaveRequest_MyApprovals}
            element={<BackContainer MyContent={<MyLeaveRequestApprovals />} />}
          ></Route>
          <Route
            path={enumRoutes.LeaveRequest_MyRequests}
            element={<BackContainer MyContent={<MyLeaveRequests />} />}
          ></Route>

          {/* <Route path={enumRoutes.Staff_ActionPoints_Search} element={<BackContainer MyContent={<StaffActionPoints />} />}></Route> */}
          {/* <Route path={enumRoutes.Staff_Dashboard} element={<BackContainer MyContent={<StaffActionPoints />} />}></Route> */}

          {/* 
            *************************
            Declaration SECTION 
            *************************
          */}
          <Route
            path={enumRoutes.Declaration_New}
            element={<BackContainer MyContent={<DeclarationManage />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_Edit}
            element={<BackContainer MyContent={<DeclarationManage />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_BudgetHolderApproval}
            element={<BackContainer MyContent={<DeclarationApproval />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_Submit}
            element={<BackContainer MyContent={<DeclarationPreview />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_Preview}
            element={<BackContainer MyContent={<DeclarationPreview />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_FinanceApproval}
            element={<BackContainer MyContent={<DeclarationApproval />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_MyDeclarations}
            element={<BackContainer MyContent={<MyDeclarations />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_MyApprovals}
            element={<BackContainer MyContent={<MyDeclarationApprovals />} />}
          ></Route>

          <Route
            path={enumRoutes.Declaration_Cost_Edit}
            element={<BackContainer MyContent={<DeclarationCostManage />} />}
          ></Route>
          <Route
            path={enumRoutes.Declaration_Diem_Edit}
            element={<BackContainer MyContent={<DeclarationDiemManage />} />}
          ></Route>

          <Route
            path={enumRoutes.User_Overview}
            element={<BackContainer MyContent={<UserOverview />} />}
          ></Route>
          <Route
            path={enumRoutes.User_ChangePassword}
            element={<BackContainer MyContent={<UserChangePassword />} />}
          ></Route>

          <Route
            path={enumRoutes.Timesheet_FinTime_MyTimeEntries}
            element={
              <BackContainer
                MyContent={<MyTimeEntries />}
              />
            }
          ></Route>

          <Route
            path={enumRoutes.Timesheet_FinTime_Edit}
            element={
              <BackContainer
                MyContent={<FinTimeManage />}
              />
            }
          ></Route>

        <Route
            path={enumRoutes.Timesheet_FinTime_Verify}
            element={
              <BackContainer
                MyContent={<FinTimeVerify />}
              />
            }
          ></Route>



          <Route
            path={enumRoutes.Timesheet_FinTime_Preview}
            element={
              <BackContainer
                MyContent={<FinTimePreview />}
              />
            }
          ></Route>



          {/* 
            *************************
            Leave Request  SECTION for Admins 
            *************************
          */}
          <Route
            path="/Documents"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >
            <Route
              path={enumRoutes.Documents_Search}
              element={<BackContainer MyContent={<DocumentSearch />} />}
            ></Route>
          </Route>
          {/* 
            *************************
            Leave Request  SECTION for Admins 
            *************************
          */}
          <Route
            path="/LeaveRequest"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >
            <Route
              path={enumRoutes.LeaveRequest_Search}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<LeaveRequestSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.LeaveRequest_Report_Summary}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<ReportLeaveRequestSummary />}
                />
              }
            ></Route>
          </Route>

          {/* 
            *************************
            Declrations for Admins 
            *************************
          */}
          <Route
            path="/Declaration"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >
            <Route
              path={enumRoutes.Declaration_Search}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<DeclarationSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Declaration_PreviewAdmin}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<DeclarationPreview />}
                />
              }
            ></Route>


            <Route
              path={enumRoutes.Declration_ProjectBudgetHolderChange}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<DeclarationProjectBudgetHolderChange />}
                />
              }
            ></Route>
          </Route>


          <Route
            path="/RepTimesheet"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >
            <Route
              path={enumRoutes.RepTimesheet_EmployeeAllocation}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<FinRepEmployeeAllocation />}
                />
              }
            ></Route>
          </Route>

          <Route
            path="/Employee"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >
            <Route
              path={enumRoutes.Employee_Dashboard}
              element={
                <BackContainer Section="Admin" MyContent={<ActionPoints />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_ActionPoints}
              element={
                <BackContainer Section="Admin" MyContent={<ActionPoints />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_New}
              element={
                <BackContainer Section="Admin" MyContent={<EmployeeManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_Edit}
              element={
                <BackContainer Section="Admin" MyContent={<EmployeeManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_Contract_Get}
              element={
                <BackContainer Section="Admin" MyContent={<ContractManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_Overview}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<EmployeeOverview />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_Search}
              element={
                <BackContainer Section="Admin" MyContent={<EmployeeSearch />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Employee_TransferHoursSearch}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<TransferHoursSearch />}
                />
              }
            ></Route>
          </Route>

          {/* 
            *************************
            Timesheet for Admins 
            *************************
          */}
          <Route
            path="/Timesheet"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >

            <Route
              path={enumRoutes.Timesheet_FinTime_EditAdmin}
              element={
                <BackContainer Section="Finance" MyContent={<FinTimeManage />} />
              }
            ></Route>


            <Route
              path={enumRoutes.Timesheet_FinTime_DetailPreview}
              element={
                <BackContainer Section="Finance"
                  MyContent={<FinTimeDetailPreview />}
                />
              }
            ></Route>


            <Route
              path={enumRoutes.Timesheet_Payroll_EmployeeOverview}
              element={
                <BackContainer Section="Finance"
                  MyContent={<PayrolEmployeeOveview />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinTime_PreviewAdmin}
              element={
                <BackContainer Section="Finance" MyContent={<FinTimePreview />} />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_Payroll_Manage}
              element={
                <BackContainer Section="Finance" MyContent={<PayrollManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_Payroll_Verify}
              element={
                <BackContainer Section="Finance" MyContent={<PayrollVerify />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_Payroll_Submit}
              element={
                <BackContainer Section="Finance" MyContent={<PayrollSubmit />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_Payroll_ManageByArgs}
              element={
                <BackContainer Section="Finance" MyContent={<PayrollManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_Payroll_Search}
              element={
                <BackContainer Section="Finance" MyContent={<PayrollSearch />} />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinProject_New}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectManage />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinTime_Search}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinTimeSearch />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinProject_Search}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectSearch />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinProject_Submit}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectSubmit />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinProject_Verify}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectVerify />}
                />
              }
            ></Route>


            <Route
              path={enumRoutes.Timesheet_FinProject_Preview}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectPreview />}
                />
              }
            ></Route>


            <Route
              path={enumRoutes.Timesheet_FinAllocation_Search}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinAllocationSearch />}
                />
              }
            ></Route>


            <Route
              path={enumRoutes.Timesheet_FinProject_Edit}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectManage />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_FinProject_OverviewPlanning}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectOverviewPlanning />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_FinEmployee_Planning}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinEmployeePlanning />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_FinEmployee_Search}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinEmployeeSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_FinProject_Edit_WithMsg}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinProjectManage />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_FinBudget_Edit}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinBudgetManage />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinAllocation_Edit}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinAllocationManage />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_FinAllocation_EditWithArgs}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinAllocationManage />}
                />
              }
            ></Route>

            <Route
              path={enumRoutes.Timesheet_FinAllocation_ByEmployee}
              element={
                <BackContainer
                  Section="Finance"
                  MyContent={<FinAllocationByEmployee />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Timesheet_Office_Search}
              element={
                <BackContainer Section="Finance" MyContent={<OfficeSearch />} />
              }
            ></Route>
          </Route>

          {/* 
            *************************
            Vacancy  SECTION 
            *************************
          */}
          <Route
            path="/Vacancy"
            element={
              <RequireRoles
                roles={[
                  enumConfig.ROLE_ADMIN,
                  enumConfig.ROLE_HR_ADMIN,
                  enumConfig.ROLE_FINANCE_ADMIN,
                ]}
              />
            }
          >
            <Route
              path={enumRoutes.Vacancy_Search}
              element={
                <BackContainer Section="Admin" MyContent={<VacancySearch />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Vacancy_New}
              element={
                <BackContainer Section="Admin" MyContent={<VacancyManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.Vacancy_Applicant}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<VacancyApplicant />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Vacancy_Edit}
              element={
                <BackContainer Section="Admin" MyContent={<VacancyManage />} />
              }
            ></Route>
            <Route
              path={enumRoutes.VacancyApplicant_Search}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<VacancyApplicantSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.VacancyApplicant_Preview}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<VacancyApplicant />}
                />
              }
            ></Route>
          </Route>

          {/* 
            *************************
            System User SECTION 
            *************************
          */}
          <Route
            path="/SystemUser"
            element={<RequireRoles roles={[enumConfig.ROLE_ADMIN]} />}
          >
            <Route
              path={enumRoutes.SystemUser_New}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<SystemUserManage />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.SystemUser_Search}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<SystemUserSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.SystemUser_Edit}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<SystemUserManage />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.SystemUser_Overview}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<SystemUserOverview />}
                />
              }
            ></Route>
          </Route>

          {/* 
                *************************
                Notification SECTION 
                *************************
              */}
          <Route
            path="/Notification"
            element={<RequireRoles roles={[enumConfig.ROLE_ADMIN]} />}
          >
            <Route
              path={enumRoutes.Notification_Search}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<NotificationSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Notification_New}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<NotificationManage />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Notification_Bulk}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<NotificationBulkManage />}
                />
              }
            ></Route>
          </Route>

          {/* 
            *************************
            Exact Online SECTION 
              *************************
            */}
          <Route
            path="/Exactonline"
            element={
              <RequireRoles
                roles={[enumConfig.ROLE_ADMIN, enumConfig.ROLE_FINANCE_ADMIN]}
              />
            }
          >
            <Route
              path={enumRoutes.Exactonline_Search}
              element={
                <BackContainer
                  Section="ExactOnline"
                  MyContent={<ExactonlineSearch />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Exactonline_Overview}
              element={
                <BackContainer
                  Section="ExactOnline"
                  MyContent={<ExactonlineOverview />}
                />
              }
            ></Route>
            <Route
              path={enumRoutes.Exactonline_Migration}
              element={
                <BackContainer
                  Section="ExactOnline"
                  MyContent={<ExactonlineMigration />}
                />
              }
            ></Route>
          </Route>

          {/* 
                  *************************
                  System Log SECTION 
                  *************************
                */}
          <Route
            path="/SystemLog"
            element={<RequireRoles roles={[enumConfig.ROLE_ADMIN]} />}
          >
            <Route
              path={enumRoutes.SystemLog_Search}
              element={
                <BackContainer
                  Section="Admin"
                  MyContent={<SystemLogSearch />}
                />
              }
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// export function RequireRole({ role, children }) {

//   const isSigned = new AuthenticateHelper().IsSigned();

//   //ako user-ot ne e najden odlogiraj go
//   if (isSigned === false) {
//     return <Navigate to={enumRoutes.Security_Logout} />
//   }
//   else {
//     const currentRole = new AuthenticateHelper().GetRole();
//     if (!(role === currentRole)) { //undefined
//       return <Navigate to={enumRoutes.Security_Logout} />
//     }
//   }
//   return children;
// }

export function RequireRoles({ roles }) {
  const isSigned = new AuthenticateHelper().IsSigned();

  //ako user-ot ne e najden odlogiraj go
  if (isSigned === false) {
    return <Navigate to={enumRoutes.Security_Logout} />;
  } else {
    //proveruvaj role po role dali postoi i ako ne postoi odlogiraj go!
    let isRoleFound = false;

    const serverRoles = new AuthenticateHelper().GetRole();
    const arrServerRoles = serverRoles.split(",");

    const clientRoles = roles;
    clientRoles.forEach((clientRole) => {
      arrServerRoles.forEach((serverRole) => {
        if (clientRole === serverRole) {
          isRoleFound = true;
        }
      });
    });

    if (!isRoleFound) {
      return <Navigate to={enumRoutes.Security_Logout} />;
    }
  }
  return <Outlet />;
}
