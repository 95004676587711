// @ts-check
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumLocalStorageArgs, enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Grid, Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter, TableHead,
  TableRow
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import PayrollActions from "../../actions/PayrollActions";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import DocumentActions from "../../actions/DocumentActions";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2025,
  Month: 1,
  Lock: false,
  OfficeName: "",
  VerifierName: "", //Verifier
  PayrollSummaryID: 0
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function PayrollSubmit() {
  const [Record, setRecord] = useState(RecordDefault);
  const [UploadedFileData, setUploadedFileData] = useState(null);

  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [RecordsSum, setRecordsSum] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [IsVerified, setIsVerified] = useState(false);
  const [IsModalOpenSubmit, setIsModalOpenSubmit] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [IsModalOpenVerify, setIsModalOpenVerify] = useState(false);
  const [ModalMessage, setModalMessage] = useState("");
  const [LookupData, setLookupData] = useState([]);
  const [OfficeData, setOfficeData] = useState([]);
  const [CurrencyExcRates, setCurrencyExcRates] = useState([]);
  const { OfficeID, Year, Month } = useParams(); //Timesheet_Payroll_Verify: "/Timesheet/payroll_verify/OfficeID/Year/Month", //OfficeID/Year/month
  const [IsLocked, setIsLocked] = useState(false);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);

      RecordDefault.OfficeID = parseInt(OfficeID); //od Request
      RecordDefault.Month = parseInt(Month); //od Request
      RecordDefault.Year = parseInt(Year); //od Request
      setRecord(RecordDefault);


      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new PayrollActions().InitAsync(RecordDefault); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let officeData = responses[1].data;

        setLookupData(lookupData);

        setOfficeData(officeData);

        //setYearsData(yearsData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses = await new PayrollActions().SearchAsync(Record);
    if (!responses.error) {
      const records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];

      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click

      setRecords(records);

      //napravi sum records
      makeSumRecord(records);


      if (records.length === 0) {
        result = msgHelper.setPhase(
          enumPage.LoadSuccessWithWarningMsg,
          null,
          "No records found"
        );
        setMessageData(result);
        return;
      }

      //odreduva dali ke zakluci cel period i ke napise poraka
      let firstRecordForLockUnlock = records[0];
      let IsSubmitted = firstRecordForLockUnlock.prIsVerified;
      let isVerified = firstRecordForLockUnlock.prIsVerified2;

      let verifierName = firstRecordForLockUnlock.prVerifierFullName;
      let officeName = firstRecordForLockUnlock.prEmployeeOffice;
      let PayrollSummaryID = firstRecordForLockUnlock.prPayrollSummaryID;

      setRecord((a) => ({
        ...a,
        ["VerifierName"]: verifierName,
        ["OfficeName"]: officeName,
        ["PayrollSummaryID"]: PayrollSummaryID
      }));

      setIsSubmitted(IsSubmitted);
      setIsVerified(isVerified);

      setMessageData(result);

      //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback
      new LocalStorageHelper().SetJSON(
        enumLocalStorageArgs.PayrollSearch,
        Record
      );

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }


  const makeSumRecord = (currentRecords) => {
    let newSumRecord = {};

    newSumRecord.prSparkAmount = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.prSparkAmount)),
      0
    );


    setRecordsSum(newSumRecord);
  };


  const handleFileUpload = (e) => {
    const { files, name } = e.target;

    const file = files[0];

    if (file) {
      setUploadedFileData(file);
    }
  };



  const lockUnlockData = (IsOk) => {

    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(
        enumPage.LoadStartWithMsg,
        null,
        "This process could take a while, please wait!"
      );
      setMessageData(result);


      //First do Upload and  link with the PayrollSummary
      let payload = { FileData: UploadedFileData, ID: Record.PayrollSummaryID };
      let responseFile = await new PayrollActions().UploadFileAsync(payload);
      if (responseFile.error) {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responseFile);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));
      }
      else {
        //Record.prsGoogleDocFileID = responseFile[0]; //samiot fajl
        let response = await new PayrollActions().SubmitAsync(Record);

        if (!response.error) {
          await searchDataAsync();

          setIsLocked(Record.Lock);

          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithMsg,
            null,
            "Payroll has been submitted for " +
            Record.Month +
            "/" +
            Record.Year +
            " for office:" +
            Record.OfficeName
          );
          setMessageData(result);
        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, response);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
        }
      }
    }
    fetchData();

  };




  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={8}>
                <Typography variant="h3" align="center">
                  Payroll submission for {moment(new Date(Year, Month - 1, 1)).format("MMMM yyyy")} {Record.PayrollSummaryID}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="h6" align="center">
                  Employee Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Office Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Period
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Local Amount
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Local Currency
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Exch.Rate
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  EUR Amount
                </Typography>
              </TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                style={{ backgroundColor: row.prTypeID === 1 ? 'white' : 'lightgray' }}
                key={row.eyEmployeeID}
                sx={[styles.TDSmall]}
              >
                <TableCell sx={{ mt: 0, mb: 0, pt: 0, pb: 0, width: "25%" }}>
                  {index + 1}.
                  <Link
                    href={enumRoutes.Employee_Edit.replace(
                      ":EmployeeID",
                      row.prEmployeeID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {row.prEmployeeFullName}
                    <br />
                  </Link>
                  <i>
                    Contract period:{moment(row.prContractStart).format("DD/MM/YYYY")} -{" "}
                    {moment(row.prContractEnd).format("DD/MM/YYYY")}
                  </i>
                </TableCell>
                <TableCell sx={{ mt: 0, mb: 0, pt: 0, pb: 0, width: "20%" }}>
                  {row.prEmployeeEmail}
                  <br />
                  <i>
                    Contract amount: {row.prContractAmount} {row.prContractCurrencyName}
                  </i>
                </TableCell>

                <TableCell sx={[styles.TDSmall]}>
                  {row.prEmployeeOffice}
                  <br />
                  <i>FTE: {row.prContractFTE}</i>
                </TableCell>

                <TableCell sx={{ mt: 0, mb: 0, pt: 0, pb: 0, width: "6%" }}>
                  {row.TypeTitle} {row.prMonth}/{row.prYear}
                </TableCell>

                <TableCell align="right" sx={[styles.TDSmall]}>
                  {new CurrencyHelper().ToMoneyFormatDigits(row.prLocalAmount, 2)}
                </TableCell>

                <TableCell align="right" sx={[styles.TDSmall]}>
                  {row.prLocalCurrencyName}
                </TableCell>


                <TableCell sx={[styles.TDSmall]} align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.prExchangeRate, 2)}
                </TableCell>

                <TableCell sx={[styles.TDSmall]} align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.prSparkAmount, 2)}
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={7}>
                <Typography variant="h6" align="right">
                  Total
                </Typography>
              </TableCell>
              <TableCell align="right">
                {new CurrencyHelper().ToMoneyFormatDigits(
                  RecordsSum.prSparkAmount,
                  2
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>


      <Grid container>
        <Grid item xs={12}>
          <MyText Id='Note'
            PlaceHolder='Your verification note'
            Record={Record}
            HandleChange={handleChange}
            MultiLine={true}
            Rows={3}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <input
            accept="*"
            type="file"
            name="FileNameXXX"
            onChange={handleFileUpload}
          />
        </Grid>
      </Grid>

      <Grid container padding={1}>
        <Grid item xs={6} align="right">
          <Button name="btnApprove" variant="contained" color="success" size="small" disabled={MessageData.Loading} onClick={() => lockUnlockData()}>Submit</Button>
        </Grid>
      </Grid>

      
    </Typography>
  );
}


